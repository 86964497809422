<template>
  <div>
    <v-row class="px-5 py-2" no-gutters>
      <v-col cols="12" md="8" class="pa-2">
        <v-row no-gutters>
          <v-col cols="12" md="8" class="pa-2">
            <v-card
              height="100%"
              flat
              class="gradient pa-5 d-flex justify-center flex-column"
              dark
            >
              <h4>
                Anda merasa stres, cemas, takut, sedih, bersalah, marah? Tidak
                bisa mengatasi sendiri?
              </h4>
              <h5 class="not_bold">
                Hubungi psikolog kami melalui no. berikut: 085155199679 |
                085155199679
              </h5>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="pa-2">
            <v-row no-gutters>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Event
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #106967; height: 20px"></div>
                    <h3 class="txt_color_default">21</h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Employee
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #052633; height: 20px"></div>
                    <h3 class="txt_color_default">21</h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Associate
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #bd9028; height: 20px"></div>
                    <h3 class="txt_color_default">21</h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Klien
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #633b18; height: 20px"></div>
                    <h3 class="txt_color_default">21</h3>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="mx-2 mt-5 pa-5 timeline">
          <h3>Individu Timeline</h3>
          <div v-for="(item, n) in 3" :key="n">
            <v-hover v-if="n < 2" v-slot="{ hover }">
              <v-card
                @click="showTimeline(n)"
                style="border-radius: 16px"
                flat
                outlined
                :class="hover ? 'hover_active' : 'hover_unactive'"
              >
                <div>
                  <h4 :style="`color:${hover ? 'white' : 'black'}`">
                    Konseling - Rudianto
                  </h4>
                  <div class="d-flex align-center">
                    <v-icon :color="hover ? '#CFE1E1' : '#697174'"
                      >mdi-calendar</v-icon
                    >
                    <h4
                      class="not_bold mx-2"
                      :style="`color:${hover ? '#CFE1E1' : '#697174'}`"
                    >
                      {{ $date(new Date()).format("DD MMMM YYYY") }}
                    </h4>
                    <v-card
                      flat
                      color="#633B18"
                      dark
                      class="px-2 mr-2 d-flex align-center"
                    >
                      <h4 class="not_bold">Status : PSI</h4>
                    </v-card>
                  </div>
                </div>
                <v-progress-circular
                  :value="80"
                  color="#106967"
                  :size="60"
                  :width="7"
                >
                  <h5 :style="`color:${hover ? 'white' : '#106967'}`">80%</h5>
                </v-progress-circular>
              </v-card>
            </v-hover>
          </div>
          <div class="mt-1 d-flex align-center justify-center">
            <v-btn text depressed class="text-capitalize" to="/admin/mo/event">
              <h4 style="color: #697d85; text-decoration: underline">
                View All
              </h4>
            </v-btn>
          </div>
        </div>
        <div class="mx-2 mt-5 pa-5 timeline">
          <h3>Project Timeline</h3>
          <div v-for="(item, n) in 3" :key="n">
            <v-hover v-if="n < 2" v-slot="{ hover }">
              <v-card
                @click="showTimeline(n)"
                style="border-radius: 16px"
                flat
                outlined
                :class="hover ? 'hover_active' : 'hover_unactive'"
              >
                <div>
                  <h4 :style="`color:${hover ? 'white' : 'black'}`">
                    Konseling - PT Hara
                  </h4>
                  <div class="d-flex align-center">
                    <v-icon :color="hover ? '#CFE1E1' : '#697174'"
                      >mdi-calendar</v-icon
                    >
                    <h4
                      class="not_bold mx-2"
                      :style="`color:${hover ? '#CFE1E1' : '#697174'}`"
                    >
                      {{ $date(new Date()).format("DD MMMM YYYY") }}
                    </h4>
                    <v-card
                      flat
                      color="#633B18"
                      dark
                      class="px-2 mr-2 d-flex align-center"
                    >
                      <h4 class="not_bold">Status : PSI</h4>
                    </v-card>
                  </div>
                </div>
                <v-progress-circular
                  :value="80"
                  color="#106967"
                  :size="60"
                  :width="7"
                >
                  <h5 :style="`color:${hover ? 'white' : '#106967'}`">80%</h5>
                </v-progress-circular>
              </v-card>
            </v-hover>
          </div>
          <div class="mt-1 d-flex align-center justify-center">
            <v-btn text depressed class="text-capitalize" to="/admin/mo/event">
              <h4 style="color: #697d85; text-decoration: underline">
                View All
              </h4>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pa-2">
        <v-card flat dark class="mx-2 mt-2 pa-5 gradient">
          <calendar
            is-expanded
            nav-visibility="visible"
            ref="calendar1"
            title-position="left"
            :attributes="attrs"
          ></calendar>
          <h3 class="mt-2">Schedule</h3>
          <div class="mt-2" v-for="(item, n) in attrs" :key="n">
            <div>
              <h4 v-if="n != 0 ? item.dates > attrs[n - 1].dates : n == 0">
                {{ $date(new Date(item.dates)).format("DD MMMM YYYY") }}
              </h4>
              <h5 class="my-2">
                {{ item.popover.label }} -
                {{ item.popover.customer ? item.popover.customer : "" }}
              </h5>
              <h5 class="not_bold">
                {{ item.popover.time_start + " - " + item.popover.time_end }}
                WIB
              </h5>
              <v-divider class="my-2"></v-divider>
            </div>
          </div>
        </v-card>
        <div class="mx-2 mt-5 pa-5 timeline">
          <div class="d-flex align-center justify-space-between">
            <h3>Employee Activity</h3>
            <v-btn text small class="text-capitalize">
              <h3 style="text-decoration: underline">View All</h3>
            </v-btn>
          </div>
          <div class="mt-3">
            <div
              class="mb-3 activity pa-3"
              v-for="(item, n) in dataSet"
              :key="n"
            >
              <h4 class="txt_white">{{ item.nama }} - {{ item.jabatan }}</h4>
              <div v-for="(timeline, y) in item.timeline" :key="y">
                <div v-if="item.expanded" class="mt-1 d-flex align-center">
                  <h5 class="not_bold txt_white">
                    {{ timeline.activity }}
                  </h5>
                  <v-spacer></v-spacer>
                  <v-card
                    dark
                    class="text-capitalize ml-2"
                    flat
                    :color="
                      timeline.status == 'process'
                        ? '#EDD57B'
                        : timeline.status == 'problem'
                        ? '#DC3545'
                        : '#26AF65'
                    "
                  >
                    <p class="pa-1 ma-0 small_txt">
                      {{ timeline.status }}
                    </p>
                  </v-card>
                </div>
                <div
                  v-if="!item.expanded && y == 0"
                  class="mt-1 d-flex align-center"
                >
                  <h5 class="not_bold txt_white">
                    {{ timeline.activity }}
                  </h5>
                  <v-spacer></v-spacer>
                  <v-card
                    dark
                    class="text-capitalize ml-2"
                    flat
                    :color="
                      timeline.status == 'process'
                        ? '#EDD57B'
                        : timeline.status == 'problem'
                        ? '#DC3545'
                        : '#26AF65'
                    "
                  >
                    <p class="pa-1 ma-0 small_txt">
                      {{ timeline.status }}
                    </p>
                  </v-card>
                </div>
              </div>
              <div class="mt-1 d-flex align-center justify-center">
                <v-btn
                  dark
                  icon
                  depressed
                  @click="item.expanded = !item.expanded"
                  small
                >
                  <v-icon small>{{
                    !item.expanded ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "mainDashboard",
  components: {},
  data() {
    return {
      attrs: [
        {
          bar: "red",
          highlight: {
            style: {
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Tes Psikologi",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date(),
        },
        {
          bar: "yellow",
          highlight: {
            style: {
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Assessment Tes",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date(),
        },
        {
          bar: "blue",
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Interview",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date("2022,03,22"),
        },
      ],
      dataSet: [
        {
          id: 1,
          nama: "John Doe",
          jabatan: "Staff",
          expanded: false,
          timeline: [
            {
              id: 1,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "process",
            },
            {
              id: 2,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "problem",
            },
            {
              id: 3,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "done",
            },
          ],
        },
        {
          id: 2,
          nama: "Brie Larson",
          jabatan: "Staff",
          expanded: false,
          timeline: [
            {
              id: 1,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "problem",
            },
            {
              id: 2,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "done",
            },
            {
              id: 3,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "process",
            },
          ],
        },
        {
          id: 3,
          nama: "Jarvis",
          jabatan: "Manager",
          expanded: false,
          timeline: [
            {
              id: 1,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "problem",
            },
            {
              id: 2,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "done",
            },
            {
              id: 3,
              activity: "Handle Psikotes Karyawan PT Hara",
              status: "process",
            },
          ],
        },
      ],
      alert: true,
      d_large: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
     
    },
    showTimeline(item) {
      this.inventory = {
        id: 1,
        nama: "Rudianto",
        layanan: "Timeline Konseling",
        timeline: [
          {
            id: 1,
            step: "Registrasi",
            status: "1",
          },
          {
            id: 2,
            step: "Invoice",
            status: "0",
          },
          {
            id: 3,
            step: "Tes Psikologi",
            status: "0",
          },
          {
            id: 4,
            step: "Interview",
            status: "0",
          },
          {
            id: 5,
            step: "Laporan",
            status: "0",
          },
        ],
      };
      this.d_type = "timeline_dashboard";
      this.d_title = "";
      this.d_large = true;
      this.line = false;
    },
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(76.81deg, #052633 5.79%, #106967 96.13%);
  border-radius: 16px;
}
.shadow {
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
}
.timeline {
  box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}
.activity {
  background: linear-gradient(180deg, #052633 0%, #106769 42.71%);
  border-radius: 8px;
}
.hover_active {
  background: linear-gradient(180deg, #052633 61.98%, #106967 100%);
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
.hover_unactive {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
