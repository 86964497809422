<template>
  <div>
    <dashboard-biro v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'" />
    <!-- <dashboard-default v-if="role == 'ACCOUNTOFFICER'"/> -->
    <dashboard-superadmin v-if="role == 'SUPERADMIN' || role == 'ACCOUNTOFFICER'"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardBiro from "./DashboardBiro.vue";
import DashboardDefault from "./DashboardDefault.vue";
import DashboardSuperadmin from './DashboardSuperadmin.vue';
export default {
  name: "mainDashboard",
  components: { DashboardBiro, DashboardDefault, DashboardSuperadmin },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
