<template>
  <div>
    <v-row class="px-5 py-2" no-gutters>
      <v-col cols="12" md="8" class="pa-2">
        <v-row no-gutters>
          <v-col cols="12" md="8" class="pa-2">
            <v-card
              height="100%"
              flat
              class="gradient pa-5 d-flex justify-center flex-column"
              dark
            >
              <h4>
                Anda merasa stres, cemas, takut, sedih, bersalah, marah? Tidak
                bisa mengatasi sendiri?
              </h4>
              <h5 class="not_bold">
                Hubungi psikolog kami melalui no. berikut: 085155199679 |
                085155199679
              </h5>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="pa-2">
            <v-row no-gutters>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Event
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #106967; height: 20px"></div>
                    <h3 v-if="!loading && dataSet" class="txt_color_default">
                      {{dataSet.total_event?dataSet.total_event:"0"}}
                    </h3>
                    <v-skeleton-loader
                      v-if="loading"
                      width="50%"
                      height="30px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Employee
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #052633; height: 20px"></div>
                    <h3 v-if="!loading && dataSet" class="txt_color_default">
                      {{ dataSet.kegiatan?dataSet.kegiatan.length:"0" }}
                    </h3>
                    <v-skeleton-loader
                      v-if="loading"
                      width="50%"
                      height="30px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Associate
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #bd9028; height: 20px"></div>
                    <h3 v-if="!loading && dataSet" class="txt_color_default">
                      {{ dataSet.associate?dataSet.associate:"0" }}
                    </h3>
                    <v-skeleton-loader
                      v-if="loading"
                      width="50%"
                      height="30px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" md="6" sm="3" class="pa-1">
                <div class="shadow">
                  Klien
                  <div
                    class="mt-2 d-flex align-center justify-space-between"
                    style="width: 60%"
                  >
                    <div style="border: 2px solid #633b18; height: 20px"></div>
                    <h3 v-if="!loading && dataSet" class="txt_color_default">
                      {{ dataSet.klien?dataSet.klien:"0" }}
                    </h3>
                    <v-skeleton-loader
                      v-if="loading"
                      width="50%"
                      height="30px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="mx-2 mt-5 pa-5 timeline">
          <h3>Individu Timeline</h3>
          <div v-if="!loading && dataSet">
            <p
              v-if="dataSet.event_individu.length == 0"
              class="medium_txt text-left"
              style="padding-top: 8px; color: #9ba0a3"
            >
              Tidak ada Individu Timeline
              <v-divider></v-divider>
            </p>
            <div v-for="(item, n) in dataSet.event_individu" :key="n">
              <v-hover v-if="n < 2" v-slot="{ hover }">
                <v-card
                  @click="showTimeline(item)"
                  style="border-radius: 16px"
                  flat
                  outlined
                  :class="hover ? 'hover_active' : 'hover_unactive'"
                >
                  <div>
                    <h4 :style="`color:${hover ? 'white' : 'black'}`">
                      {{ item.layanan }} - {{ item.customer }}
                    </h4>
                    <div class="d-flex align-center">
                      <v-icon :color="hover ? '#CFE1E1' : '#697174'"
                        >mdi-calendar</v-icon
                      >
                      <h4
                        class="not_bold mx-2"
                        :style="`color:${hover ? '#CFE1E1' : '#697174'}`"
                      >
                        {{
                          $date(new Date(item.timeline[0].start_date)).format(
                            "DD MMMM YYYY"
                          )
                        }}
                      </h4>
                      <v-card
                        flat
                        color="#633B18"
                        dark
                        class="px-2 mr-2 d-flex align-center"
                      >
                        <h4 class="not_bold">
                          Status : {{ item.timeline_stop }}
                        </h4>
                      </v-card>
                    </div>
                  </div>
                  <v-progress-circular
                    :value="((item.idx + 1) / item.timeline.length) * 100"
                    color="#106967"
                    :size="60"
                    :width="7"
                  >
                    <h5 :style="`color:${hover ? 'white' : '#106967'}`">
                      {{
                        Math.round(
                          ((item.idx + 1) / item.timeline.length) * 100
                        )
                      }}%
                    </h5>
                  </v-progress-circular>
                </v-card>
              </v-hover>
            </div>
            <div
              v-if="dataSet.event_individu.length > 1"
              class="mt-1 d-flex align-center justify-center"
            >
              <v-btn
                text
                depressed
                class="text-capitalize"
                to="/admin/mo/event"
              >
                <h4 style="color: #697d85; text-decoration: underline">
                  View All
                </h4>
              </v-btn>
            </div>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              v-for="(item, n) in 2"
              :key="n"
              height="70px"
              type="image"
              class="rounded-lg mt-2"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="mx-2 mt-5 pa-5 timeline">
          <h3>Project Timeline</h3>
          <div v-if="!loading && dataSet">
            <p
              v-if="dataSet.event_project.length == 0"
              class="medium_txt text-left"
              style="padding-top: 8px; color: #9ba0a3"
            >
              Tidak ada Project Timeline
              <v-divider></v-divider>
            </p>
            <div v-for="(item, n) in dataSet.event_project" :key="n">
              <v-hover v-if="n < 2" v-slot="{ hover }">
                <v-card
                  @click="showTimeline(item)"
                  style="border-radius: 16px"
                  flat
                  outlined
                  :class="hover ? 'hover_active' : 'hover_unactive'"
                >
                  <div>
                    <h4 :style="`color:${hover ? 'white' : 'black'}`">
                      {{ item.layanan }} - {{ item.customer }}
                    </h4>
                    <div class="d-flex align-center">
                      <v-icon :color="hover ? '#CFE1E1' : '#697174'"
                        >mdi-calendar</v-icon
                      >
                      <h4
                        class="not_bold mx-2"
                        :style="`color:${hover ? '#CFE1E1' : '#697174'}`"
                      >
                        {{
                          $date(new Date(item.timeline[0].start_date)).format(
                            "DD MMMM YYYY"
                          )
                        }}
                      </h4>
                      <v-card
                        flat
                        color="#633B18"
                        dark
                        class="px-2 mr-2 d-flex align-center"
                      >
                        <h4 class="not_bold">
                          Status : {{ item.timeline_stop }}
                        </h4>
                      </v-card>
                    </div>
                  </div>
                  <v-progress-circular
                    :value="((item.idx + 1) / item.timeline.length) * 100"
                    color="#106967"
                    :size="60"
                    :width="7"
                  >
                    <h5 :style="`color:${hover ? 'white' : '#106967'}`">
                      {{
                        Math.round(
                          ((item.idx + 1) / item.timeline.length) * 100
                        )
                      }}%
                    </h5>
                  </v-progress-circular>
                </v-card>
              </v-hover>
            </div>
            <div
              v-if="dataSet.event_project.length > 1"
              class="mt-1 d-flex align-center justify-center"
            >
              <v-btn
                text
                depressed
                class="text-capitalize"
                to="/admin/mo/event"
              >
                <h4 style="color: #697d85; text-decoration: underline">
                  View All
                </h4>
              </v-btn>
            </div>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              v-for="(item, n) in 2"
              :key="n"
              height="70px"
              type="image"
              class="rounded-lg mt-2"
            ></v-skeleton-loader>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pa-2">
        <v-card flat dark class="mx-2 mt-2 pa-5 gradient">
          <div v-if="!loading && dataSet">
            <date-picker
              is-expanded
              nav-visibility="visible"
              ref="calendar1"
              title-position="left"
              :attributes="dataSet.calendar"
              v-model="date"
              @input="changeCalendar"
            ></date-picker>
            <h3 class="mt-2">Schedule</h3>
            <p
              v-if="calendar_aktif.length == 0"
              class="medium_txt"
              style="padding-top: 8px; color: #9ba0a3"
            >
              Tidak ada event hari ini
            </p>
            <div v-for="(item, n) in calendar_aktif" :key="n">
              <div
                v-if="
                  $date(new Date(item.dates.start)).format('YYYY-MM-DD') <=
                    $date(new Date(date)).format('YYYY-MM-DD') &&
                  $date(new Date(item.dates.end)).format('YYYY-MM-DD') >=
                    $date(new Date(date)).format('YYYY-MM-DD')
                "
              >
                <div class="mt-2" v-if="n < 3">
                  <h4 v-if="n == 0">
                    {{ $date(new Date(date)).format("DD MMMM YYYY") }}
                  </h4>
                  <h4 class="my-2">
                    {{ item.popover.label }} -
                    {{ item.popover.customer ? item.popover.customer : "" }}
                  </h4>
                  <p class="ma-0 x_small_txt">
                    {{
                      item.popover.time_start + " - " + item.popover.time_end
                    }}
                    WIB
                  </p>
                  <v-divider class="my-2"></v-divider>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              height="200px"
              type="image"
              class="rounded-lg"
            ></v-skeleton-loader>
            <v-skeleton-loader
              height="70px"
              type="image"
              class="rounded-lg mt-2"
            ></v-skeleton-loader>
          </div>
        </v-card>
        <div class="mx-2 mt-5 pa-5 timeline">
          <div class="d-flex align-center justify-space-between">
            <h3>Employee Activity</h3>
            <v-btn
              to="/admin/mk/task-management"
              text
              small
              class="text-capitalize"
            >
              <h3 style="text-decoration: underline">View All</h3>
            </v-btn>
          </div>
          <div v-if="!loading && dataSet" class="mt-3">
            <div v-for="(item, n) in dataSet.kegiatan" :key="n">
              <div v-if="n < 3" class="mb-3 activity pa-3">
                <h4 class="txt_white">
                  {{ item.karyawan.nama }} - {{ item.karyawan.jabatan }}
                </h4>
                <div v-for="(task, y) in item.task" :key="y">
                  <div v-if="item.expanded" class="mt-1 d-flex align-center">
                    <h5 class="not_bold txt_white">
                      {{
                        task.detailTask
                          ? `${task.detailTask.goal} - ${task.klien.nama}`
                          : `Detail kegiatan kosong - ${task.klien.nama}`
                      }}
                    </h5>
                    <v-spacer></v-spacer>
                    <v-card
                      dark
                      class="text-capitalize ml-2"
                      flat
                      :color="
                        task.status == 'ongoing'
                          ? '#EDD57B'
                          : task.status == 'overdue'
                          ? '#DC3545'
                          : '#26AF65'
                      "
                    >
                      <p class="pa-1 ma-0 small_txt">
                        {{ task.status }}
                      </p>
                    </v-card>
                  </div>
                  <div
                    v-if="!item.expanded && y == 0"
                    class="mt-1 d-flex align-center"
                  >
                    <h5 class="not_bold txt_white">
                      {{
                        task.detailTask
                          ? `${task.detailTask.goal} - ${task.klien.nama}`
                          : `Detail kegiatan kosong - ${task.klien.nama}`
                      }}
                    </h5>
                    <v-spacer></v-spacer>
                    <v-card
                      dark
                      class="text-capitalize ml-2"
                      flat
                      :color="
                        task.status == 'ongoing'
                          ? '#EDD57B'
                          : task.status == 'overdue'
                          ? '#DC3545'
                          : '#26AF65'
                      "
                    >
                      <p class="pa-1 ma-0 small_txt">
                        {{ task.status }}
                      </p>
                    </v-card>
                  </div>
                </div>
                <div
                  v-if="item.task.length > 1"
                  class="mt-1 d-flex align-center justify-center"
                >
                  <v-btn
                    dark
                    icon
                    depressed
                    @click="item.expanded = !item.expanded"
                    small
                  >
                    <v-icon small>{{
                      !item.expanded ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="dataSet.kegiatan.length == 0">
              <p class="medium_txt text-left" style="padding-top: 8px; color: #9ba0a3">
                Tidak ada Employee Activity
                <v-divider></v-divider>
              </p>
            </div>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              v-for="(item, n) in 3"
              :key="n"
              height="70px"
              type="image"
              class="rounded-lg mt-2"
            ></v-skeleton-loader>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "mainDashboard",
  components: {},
  data() {
    return {
      dataSet: null,
      event: null,
      date: this.$date().format("YYYY-MM-DD"),
      alert: false,
      d_large: false,
      d_type: "",
      d_title: "",
      inventory: "",
      loading: false,
      line: "",
      colors: ["red", "blue", "yellow", "green", "purple"],
      calendar_aktif: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/dashboard/akun-biro`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          let event_individu = [];
          let event_project = [];
          let calendar = [];
          this.event = data.event.event;
          data.event.event.forEach((item) => {
            if (item.tipe == "project") {
              event_project.push(item);
            } else {
              event_individu.push(item);
            }
            item.timeline.forEach((timeline) => {
              let tempCalendar = {
                bar: this.colors[this.rnd(0, this.colors.length - 1)],
                highlight: {
                  style: {
                    borderRadius: "2px",
                    width: "100%",
                    height: "100%",
                  },

                  contentStyle: {
                    color: "black",
                    fontSize: "small",
                  },
                },
                popover: {
                  label: timeline.step,
                  deskripsi: item.deskripsi,
                  visibility: "click",
                  customer: item.customer,
                  time_start: timeline.start_time,
                  time_end: timeline.end_time,
                },
                dates: {
                  start: timeline.start_date,
                  end: timeline.end_date,
                },
              };
              calendar.push(tempCalendar);
              if (
                this.$date(new Date(timeline.start_time)).format(
                  "YYYY-MM-DD"
                ) <= this.$date(new Date(this.date)).format("YYYY-MM-DD") &&
                this.$date(new Date(timeline.end_date)).format("YYYY-MM-DD") >=
                  this.$date(new Date(this.date)).format("YYYY-MM-DD")
              ) {
                this.calendar_aktif.push(tempCalendar);
              }
              this.changeCalendar();
            });
          });
          this.dataSet = {
            event_individu: event_individu,
            event_project: event_project,
            total_event: data.event.total_event,
            calendar: calendar,
            kegiatan: data.kegiatan_karyawan,
            associate: data.associate,
            klien: data.klien,
          };
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    changeCalendar() {
      this.calendar_aktif = [];
      this.event.forEach((item) => {
        item.timeline.forEach((timeline) => {
          let tempCalendar = {
            bar: this.colors[this.rnd(0, this.colors.length - 1)],
            highlight: {
              style: {
                borderRadius: "2px",
                width: "100%",
                height: "100%",
              },
              contentStyle: {
                color: "black",
                fontSize: "small",
              },
            },
            popover: {
              label: timeline.step,
              deskripsi: item.deskripsi,
              visibility: "click",
              customer: item.customer,
              time_start: timeline.start_time,
              time_end: timeline.end_time,
            },
            dates: {
              start: timeline.start_date,
              end: timeline.end_date,
            },
          };
          if (
            this.$date(new Date(timeline.start_date)).format("YYYY-MM-DD") <=
              this.$date(new Date(this.date)).format("YYYY-MM-DD") &&
            this.$date(new Date(timeline.end_date)).format("YYYY-MM-DD") >=
              this.$date(new Date(this.date)).format("YYYY-MM-DD")
          ) {
            this.calendar_aktif.push(tempCalendar);
          }
        });
      });
    },
    showTimeline(item) {
      this.inventory = item;
      this.d_type = "timeline_dashboard";
      this.d_title = "";
      this.d_large = true;
      this.line = false;
    },
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(76.81deg, #052633 5.79%, #106967 96.13%);
  border-radius: 16px;
}
.shadow {
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
}
.timeline {
  box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}
.activity {
  background: linear-gradient(180deg, #052633 0%, #106769 42.71%);
  border-radius: 8px;
}
.hover_active {
  background: linear-gradient(180deg, #052633 61.98%, #106967 100%);
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
.hover_unactive {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
