<template>
  <div>
    <v-row class="px-5 py-2" no-gutters>
      <v-col cols="12" md="6" class="pa-2">
        <v-row no-gutters>
          <v-col cols="4" md="4" sm="4" class="pa-1">
            <div class="shadow">
              Klien
              <div
                class="mt-2 d-flex align-center justify-space-between"
                style="width: 60%"
              >
                <div style="border: 2px solid #633b18; height: 20px"></div>
                <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                  {{ dataSet.karyawan?dataSet.karyawan:"0" }}
                </h3>
                <v-skeleton-loader
                  v-if="loading"
                  width="50%"
                  height="30px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="pa-1">
            <div class="shadow">
              Event Berjalan
              <div
                class="mt-2 d-flex align-center justify-space-between"
                style="width: 60%"
              >
                <div style="border: 2px solid #106967; height: 20px"></div>
                <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                  {{ dataSet.event.berjalan?dataSet.event.berjalan:"0" }}
                </h3>
                <v-skeleton-loader
                  v-if="loading"
                  width="50%"
                  height="30px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="pa-1">
            <div class="shadow">
              Event Selesai
              <div
                class="mt-2 d-flex align-center justify-space-between"
                style="width: 60%"
              >
                <div style="border: 2px solid #106967; height: 20px"></div>
                <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                  {{ dataSet.event.selesai?dataSet.event.selesai:"0" }}
                </h3>
                <v-skeleton-loader
                  v-if="loading"
                  width="50%"
                  height="30px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="pa-1">
            <div class="shadow">
              Komunitas Biro
              <div
                class="mt-2 d-flex align-center justify-space-between"
                style="width: 60%"
              >
                <div style="border: 2px solid #052633; height: 20px"></div>
                <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                  {{ dataSet.community?dataSet.community.offline + dataSet.community.online:"0" }}
                </h3>
                <v-skeleton-loader
                  v-if="loading"
                  width="50%"
                  height="30px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="pa-1">
            <div class="shadow">
              Biro Terdaftar
              <div
                class="mt-2 d-flex align-center justify-space-between"
                style="width: 60%"
              >
                <div style="border: 2px solid #bd9028; height: 20px"></div>
                <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                  {{ dataSet.biro?dataSet.biro:"0" }}
                </h3>
                <v-skeleton-loader
                  v-if="loading"
                  width="50%"
                  height="30px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="pa-2">
        <div class="timeline pa-2">
          <v-card flat dark class="mx-2 mt-2 mb-3 px-5 py-2 font-weight-bold" color="#568280">
            Log aktivitas
          </v-card>
          <div v-if="!loading && dataSet != null">
            <v-card flat dark class="mx-2 mt-1 px-5 py-2" v-for="(item, n) in dataSet.activity_log" :key="n" color="#568280">
              <div>
                <span class="text-decoration-underline font-weight-medium">
                  {{ item.nama }}
                </span>
                <br>
                {{ item.description }}
              </div>
              <div class="d-flex align-center justify-end mt-2">
                <v-icon size="15" class="mr-1">mdi-clock-outline</v-icon>
                <p class="text-right ma-0 text-caption">
                  {{  $date(item.created_at).format("DD MMM YYYY hh:mm:ss") }}
                </p>
              </div>
            </v-card>
            <div
                v-if="dataSet.total_activity > 10"
                class="mt-1 d-flex align-center justify-end"
              >
                <v-btn
                  text
                  depressed
                  class="text-capitalize"
                  to="/admin/act/activity"
                >
                  <h4 style="color: #697d85; text-decoration: underline">
                    View All
                  </h4>
                </v-btn>
            </div>
            <div v-if="dataSet.total_activity == 0">
              <div class="mx-2 mt-1 py-1 text-center">
                <p class="medium_txt text-left" style="padding-top: 8px; color: #9ba0a3">
                  Tidak ada Log aktivitas
                  <v-divider></v-divider>
                </p>
              </div>
            </div>
          </div>
          <div v-if="loading">
            <div
              v-for="(item, n) in 5"
              :key="n" class="mt-1 mx-2">
              <v-skeleton-loader
                width="100%"
                height="50px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "mainDashboard",
  components: {},
  data() {
    return {
      attrs: [
        {
          bar: "red",
          highlight: {
            style: {
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Tes Psikologi",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date(),
        },
        {
          bar: "yellow",
          highlight: {
            style: {
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Assessment Tes",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date(),
        },
        {
          bar: "blue",
          highlight: {
            style: {
              background: "transparent",
              borderRadius: "2px",
              width: "100%",
              height: "100%",
            },

            contentStyle: {
              color: "black",
              fontSize: "small",
            },
          },
          popover: {
            label: "Interview",
            visibility: "click",
            customer: "PT Hara",
            time_start: "09.10",
            time_end: "12.10",
          },
          dates: new Date("2022,03,22"),
        },
      ],
      dataSet: null,
      alert: true,
      d_large: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      loading: true,
    };
  },
  mounted() {
    this.loading = true;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/dashboard/akun-superadmin`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(76.81deg, #052633 5.79%, #106967 96.13%);
  border-radius: 10px;
}
.shadow {
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  height: 100%;
}
.timeline {
  box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}
.activity {
  background: linear-gradient(180deg, #052633 0%, #106769 42.71%);
  border-radius: 8px;
}
.hover_active {
  background: linear-gradient(180deg, #052633 61.98%, #106967 100%);
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
.hover_unactive {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
